var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.availableProducts.length > 0)?_c('div',{staticClass:"gift-list-slider-container"},[_c('h2',{staticClass:"my-3 font-weight-bold primary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$t("product.rewards.title"))+" ")]),_c('h2',{staticClass:"my-3 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("product.rewards.description"))+" ")]),(_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"slider product-slider pa-2 pb-3 pb-sm-6",class:this.cols == 'auto' ? 'slider-auto' : 'product-grid'},[_vm._t("default",function(){return [_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}})]}),_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.availableProducts),function(product){return _c('swiper-slide',{key:product.productId,staticClass:"w-100"},[_c('GiftCard',{attrs:{"minAmount":_vm.minAmount,"horizontal":_vm.horizontal,"product":product,"selectedOption":_vm.selectedOption},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', { product: product })}}})],1)}),1),(_vm.showBullets && _vm.products.length > 1)?_c('div',{staticClass:"swiper-pagination",attrs:{"id":`product-list-pagination-${_vm.paginationClass}`},on:{"click":_vm.startAutoplay}}):_vm._e(),(_vm.showArrows && _vm.products.length > 1)?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`product-list-slider-prev-${_vm.paginationClass}`},on:{"click":_vm.startAutoplay}}):_vm._e(),(_vm.showArrows && _vm.products.length > 1)?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`product-list-slider-next-${_vm.paginationClass}`},on:{"click":_vm.startAutoplay}}):_vm._e(),(_vm.showMore)?_c('div',{staticClass:"text-right show-more"},[_c('v-btn',{staticClass:"primary--text text-none",attrs:{"link":"","text":"","to":_vm.categoryPath
            ? {
                name: 'Category',
                params: { pathMatch: _vm.categoryPath },
                query: { categoryId: _vm.categoryId }
              }
            : {
                name: 'Category',
                params: {
                  pathMatch: _vm.proposal.slug
                }
              }}},[_vm._v(" mostra tutti "),_c('v-icon',[_vm._v("$chevronRight")])],1)],1):_vm._e()],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }